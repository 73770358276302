import styled from 'styled-components';

const CharactersBalanceStyle = styled.div`
	&.charactersBalance {
		width: 100%;

		.fillWhite {
			fill: var(--color1);
		}

		.fillBlack {
			fill: var(--color2);
		}

		.styleStroke {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}
	}
`;

export default CharactersBalanceStyle;
