import styled from 'styled-components';

const ZiggyHomeTopStyle = styled.div`
	&.ziggyHomeTop {
		overflow: visible;
		height: 100%;

		.zht-ex {
			opacity: 0;
		}

		.defStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fillWhite {
			fill: var(--color1);
		}

		.fillBlack {
			fill: var(--color2);
		}

		.lines {
			fill: none;
			stroke: var(--color2);
			stroke-miterlimit: 10;
			opacity: 0;
		}
	}
`;

export default ZiggyHomeTopStyle;
