import { memo as Memo, useMemo, useEffect, useRef, useCallback } from 'react';
import { gsap, TweenLite, Bounce, Power0 } from 'gsap';

//* Styles
import CharactersBalanceStyle from './style';

const CharactersBalance = Memo(() => {
	//! Refs
	const contRef = useRef();
	const animEyes = useRef();
	const tl = useRef();
	const charTl = useRef();

	//! Global Variables
	const maxRotate = useMemo(() => 10, []);

	const char1 = useMemo(
		() => ({
			eyes: [
				{ l: 'M293.72,569.93v3.51H281v-3.51Z', r: 'M330,569.93v3.51H343.2v-3.51Z' },
				{ l: 'M289.59,565h5.28l6.57,6.75-6.57,6.72h-5.28l6.54-6.72Z', r: 'M333,578.47l-6.54-6.72L333,565h5.31l-6.57,6.75,6.57,6.72Z' },
				{ l: 'M298.73,568.34l1.55,2.82-4.06,1.46,4.06,1.42L298.64,577l-3.32-2.79.72,4.31h-3.23l.68-4.31L290.15,577l-1.71-3,4.06-1.45-4-1.43,1.58-2.82,3.41,2.73-.71-4.31H296l-.72,4.31Z', r: 'M338,568.34l1.55,2.82-4.06,1.46,4.06,1.42L337.88,577l-3.31-2.79.71,4.31h-3.22l.68-4.31L329.39,577l-1.7-3,4.06-1.45-4-1.43,1.58-2.82,3.41,2.73-.71-4.31h3.25l-.71,4.31Z' },
				{ l: 'M292.31,570.31a15.43,15.43,0,0,1,1.69.84q.84.45,1.32.66a2.45,2.45,0,0,0,1,.21q1.53,0,1.86-2h3.21a7.94,7.94,0,0,1-1.83,4.38,4.62,4.62,0,0,1-3.48,1.44,4.37,4.37,0,0,1-1.62-.28,15.22,15.22,0,0,1-1.74-.86,12.37,12.37,0,0,0-1.27-.66,2.65,2.65,0,0,0-1-.21q-1.53,0-1.89,2h-3.18a8.09,8.09,0,0,1,1.86-4.38,4.58,4.58,0,0,1,3.45-1.44A4.4,4.4,0,0,1,292.31,570.31Z', r: 'M333.38,570.31a15.43,15.43,0,0,1,1.69.84q.84.45,1.32.66a2.45,2.45,0,0,0,1,.21q1.53,0,1.86-2h3.21a7.94,7.94,0,0,1-1.83,4.38,4.62,4.62,0,0,1-3.48,1.44,4.37,4.37,0,0,1-1.62-.28,15.22,15.22,0,0,1-1.74-.86,12.37,12.37,0,0,0-1.27-.66,2.65,2.65,0,0,0-1-.21q-1.53,0-1.89,2H326.4a8.09,8.09,0,0,1,1.86-4.38,4.58,4.58,0,0,1,3.45-1.44A4.4,4.4,0,0,1,333.38,570.31Z' },
			],
		}),
		[]
	);
	const char2 = useMemo(
		() => ({
			eyes: [
				{ l: 'M288.69,385.41,287,389.27l3.8.74-.74,3.77-4.59-.9-2.1,4.91-3.85-.76,2.09-4.91-5.41-1.07-2.1,4.91-3.86-.76,2.1-4.9-4.42-.87.75-3.77,5.2,1,1.68-3.85-4.3-.85.75-3.77,5.09,1,2.09-4.88,3.85.76L281,380l5.42,1.07,2.09-4.88,3.86.76-2.09,4.88,3.91.77-.74,3.76Zm-3.86-.76-5.41-1.07-1.68,3.86,5.42,1.07Z', r: 'M323.93,392.34l-1.68,3.86,3.8.74-.75,3.77-4.59-.9-2.09,4.91-3.86-.76,2.1-4.91L311.44,398l-2.1,4.91-3.85-.76,2.1-4.9-4.42-.87.74-3.77,5.21,1,1.68-3.86-4.3-.85.74-3.77,5.09,1,2.09-4.87,3.86.75-2.09,4.88L321.6,388l2.1-4.88,3.85.76-2.09,4.88,3.92.77-.75,3.76Zm-3.86-.76-5.42-1.07L313,394.37l5.41,1.07Z' },
				{ l: 'M285.55,398.42l-3.21-8-5.61,6.22-4.73-1,8.72-9.26-4.61-11.52,4.91,1,3.15,7.88,5.51-6.09,4.73,1-8.64,9.09,4.68,11.72Z', r: 'M315.46,404.6l-3.21-8-5.61,6.22-4.73-1,8.71-9.25L306,381l4.91,1,3.15,7.88,5.51-6.09,4.73,1-8.64,9.08,4.68,11.72Z' },
				{ l: 'M272.1,393.15l-4.12-.84,10.26-14.22,4,.81L286,396l-4.11-.84-2.66-11.87Z', r: 'M308.43,400.58l-4.12-.84,10.26-14.23,4,.81,3.82,17.1-4.11-.84-2.66-11.87Z' },
			],
		}),
		[]
	);
	const char3 = useMemo(
		() => ({
			eyes: [
				{
					l: 'M313.08,229.64a8.28,8.28,0,0,1,3.39,3.65,10,10,0,0,1,.8,5.29,8.63,8.63,0,0,1-1.18,3.71,6.89,6.89,0,0,1-2.45,2.49,5.11,5.11,0,0,1-3.06.71,3.76,3.76,0,0,1-2.21-.86,2.72,2.72,0,0,1-.81-2.12,5.42,5.42,0,0,1-1.95,1.82,4.66,4.66,0,0,1-2.62.45,4.32,4.32,0,0,1-2.24-.83,4.19,4.19,0,0,1-1.38-1.88,5.8,5.8,0,0,1-.32-2.64c0-.3.06-.53.09-.68a7,7,0,0,1,1.32-3.14,6.36,6.36,0,0,1,2.55-2,6.58,6.58,0,0,1,3.28-.5,5.24,5.24,0,0,1,3.52,1.56,4.27,4.27,0,0,1,1,3.44,6.3,6.3,0,0,1-.14.91l-.55,2.23c0,.25-.08.41-.09.5a1.2,1.2,0,0,0,.18.88,1.14,1.14,0,0,0,.79.32,2,2,0,0,0,1.42-.42,3.86,3.86,0,0,0,1.18-1.54,7.27,7.27,0,0,0,.64-2.56,7.59,7.59,0,0,0-.58-3.94,6.35,6.35,0,0,0-2.53-2.82,9.92,9.92,0,0,0-4.38-1.32,10.42,10.42,0,0,0-5.44.78,8.48,8.48,0,0,0-3.7,3.17,10.71,10.71,0,0,0-1.62,4.85,7.72,7.72,0,0,0,1.7,6.1q2.05,2.28,6.36,2.69l-.76,2.35a14,14,0,0,1-5.93-1.84,8.59,8.59,0,0,1-3.45-3.89,11.28,11.28,0,0,1-.78-5.68,11.52,11.52,0,0,1,7.46-10,14.71,14.71,0,0,1,6.82-.89A13.46,13.46,0,0,1,313.08,229.64Zm-5.69,6.83a2.63,2.63,0,0,0-1.84-.88,3,3,0,0,0-1.66.32,3.29,3.29,0,0,0-1.31,1.26,4.85,4.85,0,0,0-.64,2.09,2.84,2.84,0,0,0,.53,2.14,2.47,2.47,0,0,0,1.76.9,3,3,0,0,0,1.65-.32,3.41,3.41,0,0,0,1.35-1.27,4.6,4.6,0,0,0,.68-2.16A2.71,2.71,0,0,0,307.39,236.47Z',
					r: 'M351.53,233.22a8.33,8.33,0,0,1,3.39,3.65,10.12,10.12,0,0,1,.81,5.3,8.81,8.81,0,0,1-1.18,3.71,7,7,0,0,1-2.46,2.49,5,5,0,0,1-3.06.7,3.64,3.64,0,0,1-2.21-.86,2.65,2.65,0,0,1-.8-2.12,5.44,5.44,0,0,1-2,1.82,4.56,4.56,0,0,1-2.62.46,4.25,4.25,0,0,1-2.23-.83,4.19,4.19,0,0,1-1.39-1.88,5.8,5.8,0,0,1-.31-2.64c0-.31.05-.54.08-.69a7,7,0,0,1,1.32-3.13,6.36,6.36,0,0,1,2.55-2,6.48,6.48,0,0,1,3.28-.51,5.28,5.28,0,0,1,3.52,1.56,4.29,4.29,0,0,1,1,3.44,8,8,0,0,1-.15.92l-.55,2.23c-.05.24-.08.41-.09.49a1.21,1.21,0,0,0,.19.89,1.11,1.11,0,0,0,.78.32,2,2,0,0,0,1.42-.43,3.92,3.92,0,0,0,1.19-1.53,8.47,8.47,0,0,0,.05-6.5,6.3,6.3,0,0,0-2.53-2.82,10.06,10.06,0,0,0-4.38-1.33,10.64,10.64,0,0,0-5.44.78,8.5,8.5,0,0,0-3.7,3.18,10.7,10.7,0,0,0-1.61,4.85,7.63,7.63,0,0,0,1.7,6.09q2.06,2.3,6.36,2.7l-.77,2.35a13.87,13.87,0,0,1-5.92-1.84,8.62,8.62,0,0,1-3.46-3.9,11.24,11.24,0,0,1-.78-5.67,11.54,11.54,0,0,1,7.47-10,14.8,14.8,0,0,1,6.82-.88A13.46,13.46,0,0,1,351.53,233.22Zm-5.69,6.83a2.58,2.58,0,0,0-1.84-.87,2.9,2.9,0,0,0-1.66.32,3.2,3.2,0,0,0-1.31,1.25,4.94,4.94,0,0,0-.64,2.1,2.81,2.81,0,0,0,.53,2.13,2.46,2.46,0,0,0,1.76.91,3.13,3.13,0,0,0,1.66-.32,3.47,3.47,0,0,0,1.35-1.27,4.79,4.79,0,0,0,.67-2.16A2.71,2.71,0,0,0,345.84,240.05Z',
				},
				{ l: 'M301,226.42l-1.38,12.35a5.55,5.55,0,0,0,.67,3.83,4.05,4.05,0,0,0,3,1.59,4,4,0,0,0,3.25-.89,5.55,5.55,0,0,0,1.51-3.59l1.38-12.34,4.17.46-1.38,12.34a10.32,10.32,0,0,1-1.64,4.82,7.09,7.09,0,0,1-3.34,2.66,9.46,9.46,0,0,1-4.48.49,8.21,8.21,0,0,1-5.8-2.94q-2-2.52-1.52-6.9L296.87,226Z', r: 'M339.83,230.76l-1.38,12.34a5.49,5.49,0,0,0,.67,3.83,4,4,0,0,0,3,1.59,3.93,3.93,0,0,0,3.25-.89,5.52,5.52,0,0,0,1.51-3.59l1.37-12.34,4.18.47-1.38,12.34a10.28,10.28,0,0,1-1.64,4.81,7,7,0,0,1-3.35,2.66,9.4,9.4,0,0,1-4.47.5,8.21,8.21,0,0,1-5.8-2.94c-1.35-1.68-1.85-4-1.53-6.91l1.38-12.34Z' },
				{ l: 'M311.54,228a7.51,7.51,0,0,1,2.54,3.4,9.09,9.09,0,0,1,.38,4.66,9,9,0,0,1-1.81,4.3,7.58,7.58,0,0,1-3.47,2.44,9.18,9.18,0,0,1-4.48.33,9,9,0,0,1-4.15-1.71A7.56,7.56,0,0,1,298,238a8.9,8.9,0,0,1-.38-4.64,9,9,0,0,1,1.81-4.32,7.56,7.56,0,0,1,3.47-2.44,9.43,9.43,0,0,1,4.48-.32A9.23,9.23,0,0,1,311.54,228Zm-7.89,2.67a6,6,0,0,0-1.14,7.15,4.24,4.24,0,0,0,5.93.94,5.95,5.95,0,0,0,1.14-7.14,4.25,4.25,0,0,0-5.93-1Z', r: 'M348.8,233.91a7.51,7.51,0,0,1,2.55,3.4,9.1,9.1,0,0,1,.38,4.66,8.94,8.94,0,0,1-1.81,4.3,7.61,7.61,0,0,1-3.48,2.44,9.13,9.13,0,0,1-4.47.34,9.24,9.24,0,0,1-4.15-1.71,7.67,7.67,0,0,1-2.54-3.4,8.83,8.83,0,0,1-.38-4.65,8.92,8.92,0,0,1,1.81-4.31,7.44,7.44,0,0,1,3.47-2.44,9.87,9.87,0,0,1,8.62,1.37Zm-7.88,2.68a5.91,5.91,0,0,0-1.14,7.14,4.25,4.25,0,0,0,5.93,1,5,5,0,0,0,1.78-3.38,4.91,4.91,0,0,0-.65-3.76,4.22,4.22,0,0,0-5.92-.95Z' },
			],
		}),
		[]
	);
	const char4 = useMemo(
		() => ({
			eyes: [
				{ l: 'M309.4,77.83l-.12,3.51-12.75-.44.12-3.5Z', r: 'M334,78.67l-.12,3.51-12.74-.43.12-3.51Z' },
				{ l: 'M296.42,73l5.27.08,6.47,6.85-6.67,6.62-5.28-.08,6.64-6.62Z', r: 'M328.81,87l-6.43-6.82L329,73.49l5.31.08-6.67,6.64L334.12,87Z' },
				{ l: 'M304,73.72a7.49,7.49,0,0,1,3.05,3,9.82,9.82,0,0,1,0,9.07,7.62,7.62,0,0,1-3.05,3,9.76,9.76,0,0,1-8.73,0,7.59,7.59,0,0,1-3-3,9.82,9.82,0,0,1,0-9.07,7.47,7.47,0,0,1,3-3,9.85,9.85,0,0,1,8.73,0Zm-7.36,3.89a5.93,5.93,0,0,0,0,7.23,4.25,4.25,0,0,0,6,0,5.93,5.93,0,0,0,0-7.23,4.22,4.22,0,0,0-6,0Z', r: 'M335.7,73.72a7.49,7.49,0,0,1,3.05,3,9.82,9.82,0,0,1,0,9.07,7.62,7.62,0,0,1-3.05,3,9.76,9.76,0,0,1-8.73,0,7.59,7.59,0,0,1-3-3,9.82,9.82,0,0,1,0-9.07,7.47,7.47,0,0,1,3-3,9.85,9.85,0,0,1,8.73,0Zm-7.36,3.89a5.93,5.93,0,0,0,0,7.23,4.25,4.25,0,0,0,6,0,5.93,5.93,0,0,0,0-7.23,4.22,4.22,0,0,0-6,0Z' },
			],
			mouth: ['M308.5,100l4.6,0l0,4.4l-4.6,0L308.5,100z M317.8,100.1l0,4.4l4.6,0l0-4.4L317.8,100.1z', 'M318.4,100.2l-0.1,4.4l-4.5-0.2l0.1-4.4L318.4,100.2z'],
		}),
		[]
	);

	//! Event Listener
	useEffect(() => {
		window.addEventListener('mousemove', handleCoordinates);
		window.addEventListener('deviceorientation', handleCoordinates, true);

		return () => {
			disableAnimation();
			window.removeEventListener('mousemove', handleCoordinates);
			window.removeEventListener('deviceorientation', handleCoordinates);
		};
	}, [contRef]);

	//! Mouse Move
	let size = 0;
	let isActive = false;

	const handleCoordinates = useCallback((e) => {
		const cont = contRef?.current?.getBoundingClientRect();

		if (cont.top + cont.height > 0 && cont.top < window.innerHeight) {
			const winPart = window.innerWidth / 2;

			animateContainer(size);

			if (e.clientX) {
				size = (e.clientX - winPart) / winPart;
			} else if (e.gamma) {
				const minMaxDeg = 30;
				const gamma = e.gamma > minMaxDeg ? minMaxDeg : e.gamma < -minMaxDeg ? -minMaxDeg : e.gamma;
				const percent = gamma / minMaxDeg;
				size = (winPart * percent) / winPart;
			}

			if (!isActive) {
				enableAnimation();
				isActive = true;
			}
		} else {
			if (isActive) {
				isActive = false;
				disableAnimation();
			}
		}
	}, []);

	//! Animated Items
	const animatedItems = useMemo(() => ['.charsBalance', '.char1-b', '.char1-h', '.char1-bf', '.char1-el', '.char1-er', '.char2', '.char2-bf', '.char2-fl', '.char2-fr', '.char2-el', '.char2-er', '.char3', '.char3-bf', '.char3-fl', '.char3-fr', '.char3-el', '.char3-er', '.char4', '.char4-bf', '.char4-fl', '.char4-fr', '.char4-el', '.char4-er', '.char4-hl', '.char4-hr', '.char4-m'].toString(), []);

	//! Container Animation with Characters
	const animateContainer = useCallback(
		(size) => {
			TweenLite.killTweensOf(['.charsBalance', '.char1-b', '.char2', '.char3', '.char4']);
			TweenLite.to('.charsBalance', gsap.utils.random(0.5, 1), { transformOrigin: 'bottom center', rotate: size * maxRotate });
			TweenLite.to('.char1-b', gsap.utils.random(0.5, 1), { transformOrigin: 'bottom center', rotate: -size * maxRotate * 0.5, ease: Bounce.easeInOut });
			TweenLite.to('.char2', gsap.utils.random(0.5, 1), { transformOrigin: 'bottom', rotate: -size * maxRotate * 0.6, ease: Bounce.easeInOut });
			TweenLite.to('.char3', gsap.utils.random(0.5, 1), { transformOrigin: 'bottom', rotate: -size * maxRotate * 0.4, ease: Bounce.easeInOut });
			TweenLite.to('.char4', gsap.utils.random(0.5, 1), { transformOrigin: 'bottom', rotate: -size * maxRotate * 0.4, ease: Bounce.easeInOut });
		},
		[maxRotate]
	);

	//! Characters Animation
	const animateChars = useCallback(() => {
		const absSize = Math.abs(size);
		const randSize = absSize * 2 + 2;
		const minTime = 0.15 - absSize * 0.05;
		const maxTime = 0.25 - absSize * 0.1;
		const rand = gsap.utils.random(-randSize, randSize);

		gsap.killTweensOf(animatedItems);

		//! Char 1
		TweenLite.to('.char1-h', gsap.utils.random(minTime, 0.6 - absSize * 0.4), {
			transformOrigin: 'center',
			rotate: gsap.utils.random(-(absSize * 15 + 5), absSize * 8 + 2),
			ease: Power0.easeNone,
		});
		TweenLite.to('.char1-bf', gsap.utils.random(minTime, 0.6 - absSize * 0.4), {
			svgOrigin: '318 607',
			rotate: gsap.utils.random(-(absSize * 4), absSize * 1),
			ease: Power0.easeNone,
		});
		//! Char 2
		TweenLite.to('.char2-bf', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '336 494',
			rotate: rand,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char2-fr', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '336 494',
			rotate: rand,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char2-fl', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '275 447',
			rotate: gsap.utils.random(-(absSize * 20 + 10), absSize * 5),
			ease: Power0.easeNone,
		});
		//! Char 3
		TweenLite.to('.char3-bf', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '311 400',
			rotate: -rand * 0.5,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char3-fl', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '311 400',
			rotate: -rand * 0.5,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char3-fr', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '367 317',
			rotate: gsap.utils.random(-(absSize * 5 + 5), absSize * 10 + 10),
			ease: Power0.easeNone,
		});
		//! Char 4
		TweenLite.to('.char4-bf', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '308 131',
			rotate: rand * 0.5,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char4-fl', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '308 131',
			rotate: rand * 0.5,
			ease: Power0.easeNone,
		});
		TweenLite.to('.char4-fr', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '340 134',
			rotate: gsap.utils.random(-(absSize * 10 + 10), absSize * 5 + 5),
			ease: Power0.easeNone,
		});
		TweenLite.to('.char4-hl', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '250 57',
			rotate: gsap.utils.random(-(absSize * 10 + 20), absSize * 10 + 20),
			ease: Power0.easeNone,
		});
		charTl.current = TweenLite.to('.char4-hr', gsap.utils.random(minTime, maxTime), {
			svgOrigin: '406 67',
			rotate: gsap.utils.random(-(absSize * 10 + 20), absSize * 10 + 20),
			ease: Power0.easeNone,
			onComplete: animateChars,
		});
	}, []);

	//! Characters Eye Animation
	const animateEyes = useCallback(() => {
		const elNum = Math.round(Math.random() * 3 + 1);
		const obj = eval(`char${elNum}`);
		const eyes = obj.eyes[Math.round(Math.random() * (obj.eyes.length - 1))];
		const mouth = elNum === 4 && obj.mouth[Math.round(Math.random() * (obj.mouth.length - 1))];

		animEyes.current && animEyes.current.kill();
		animEyes.current = gsap.delayedCall(gsap.utils.random(1, 2), () => {
			tl.current && tl.current.kill();
			tl.current = new gsap.timeline({ onComplete: animateEyes });
			tl.current.set(
				`.char${elNum}-el`,
				{
					attr: { d: eyes.l },
				},
				0
			);
			tl.current.set(
				`.char${elNum}-er`,
				{
					attr: { d: eyes.r },
				},
				0
			);

			mouth &&
				tl.current.set(
					`.char${elNum}-m`,
					{
						attr: { d: mouth },
					},
					0
				);
		});
	}, []);

	useEffect(() => {
		enableAnimation();

		return () => {
			disableAnimation();
		};
	}, []);

	const enableAnimation = useCallback(() => {
		animateChars();
		animateEyes();
	}, []);

	const disableAnimation = useCallback(() => {
		charTl.current && charTl.current.kill();
		animEyes.current.kill();
		tl.current && tl.current.kill();

		gsap.killTweensOf(animatedItems);
	}, []);

	return (
		<CharactersBalanceStyle
			ref={contRef}
			className={'charactersBalance'}>
			<svg
				className={`charsBalance`}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 637.84 706.16'>
				<g className={`char1`}>
					<g className='char1-bf'>
						<path
							className={`char1-b fillBlack`}
							d='M379.48,666.06H234.06c0-101.61,65.11-184,145.42-184h0Z'
						/>
						<path
							className={`char1-el fillWhite`}
							d={char1.eyes[0].l}
						/>
						<path
							className={`char1-er fillWhite`}
							d={char1.eyes[0].r}
						/>
						<path
							className={`char1-h styleStroke`}
							d='M435.8,623.1c-20.1-6.5-41.8-8.9-63.8-6.7H250.9c-22-2.2-43.7,0.2-63.8,6.7'
						/>
						<path
							className={`char1-m fillWhite`}
							d='M308.21,586.25,313.88,603l5.7-16.77h4.53l-7.5,21.18h-5.43l-7.5-21.18Z'
						/>
					</g>

					<path
						className={`char1-fl styleStroke`}
						d='M330,659.79c.75,5.94,2.53,22.13,3.76,32.37a13,13,0,0,0,9.66,11l5.68,1.47'
					/>
					<path
						className={`char1-fr styleStroke`}
						d='M305.39,656.56c.19,6,1,22.25,1.4,32.56A13,13,0,0,1,299,701.54l-5.39,2.34'
					/>
				</g>

				<g className='char2'>
					<g className='char2-bf'>
						<circle
							className={`char2-b fillBlack`}
							cx='317.54'
							cy='402.44'
							r='66.64'
						/>
						<path
							className={`char2-el fillWhite`}
							d={char2.eyes[0].l}
						/>
						<path
							className={`char2-er fillWhite`}
							d={char2.eyes[0].r}
						/>
						<path
							className={`char2-fl styleStroke`}
							d='M275.53,447.72c-3.67,4.73-13.43,17.78-19.7,26a13,13,0,0,1-13.93,4.59l-5.64-1.63'
						/>
					</g>
					<path
						className={`char2-fr styleStroke`}
						d='M324.8,450.87c.54,6,1.72,22.21,2.58,32.49a13,13,0,0,0,9.25,11.37l5.63,1.67'
					/>
				</g>

				<g className={`char3`}>
					<g className='char3-bf'>
						<path
							className={`char3-b fillBlack`}
							d='M236.42,176.48l42.7,132a1.87,1.87,0,0,0,1.62,1.26l125.7,11a1.88,1.88,0,0,0,1.82-1l64.92-122.62S236,175.28,236.42,176.48Z'
						/>
						<path
							className={`char3-fr styleStroke`}
							d='M365.54,297.27c.54,6,1.72,22.21,2.58,32.49a13,13,0,0,0,9.25,11.38l5.63,1.67'
						/>
						<path
							className={`char3-m fillWhite`}
							d='M321.41,251.51A16.42,16.42,0,0,1,323,252.6a13.14,13.14,0,0,0,1.2.86,2.53,2.53,0,0,0,.94.37c1,.16,1.73-.42,2.16-1.72l3.17.5a8,8,0,0,1-2.5,4,4.62,4.62,0,0,1-3.66.87,4.43,4.43,0,0,1-1.56-.53,17,17,0,0,1-1.58-1.12c-.47-.38-.85-.66-1.15-.86a2.77,2.77,0,0,0-1-.37c-1-.16-1.74.41-2.19,1.72l-3.14-.5a8,8,0,0,1,2.53-4,4.53,4.53,0,0,1,3.63-.88A4.4,4.4,0,0,1,321.41,251.51Z'
						/>
						<path
							className={`char3-el fillWhite`}
							d={char3.eyes[0].l}
						/>
						<path
							className={`char3-er fillWhite`}
							d={char3.eyes[0].r}
						/>
					</g>

					<path
						className={`char3-fl styleStroke`}
						d='M323.6,295.5c-0.5,6-5.2,42.5-6.2,52'
					/>
				</g>

				<g className='char4'>
					<g className='char4-bf'>
						<path
							className={`char4-b fillBlack`}
							d='M344.93,33.34,317.1,0,306.39,43.59,268.93,22.3l.41,35.48-40.76-5.6L245.14,84l-25,17.69L252,111.08l1.93,32.24,33.26-22.51,11.62,33.29L324,124.26,351.85,148l15-35.52,41.69,12.82-8-37.82,41.23-11.11-39.45-15,15.64-41.87-35.51,20L374.72,3.31Z'
						/>
						<path
							className={`char4-el fillWhite`}
							d={char4.eyes[2].l}
						/>
						<path
							className={`char4-er fillWhite`}
							d={char4.eyes[2].r}
						/>
						<path
							className={`char4-m fillWhite`}
							d='M308.5,100l4.6,0l0,4.4l-4.6,0L308.5,100z M317.8,100.1l0,4.4l4.6,0l0-4.4L317.8,100.1z'
						/>
						<path
							className={`char4-hl styleStroke`}
							d='M250.74,57.53c-2-1.29-17.67-17.76,2.52-38l-8.65-7.34'
						/>
						<path
							className={`char4-hr styleStroke`}
							d='M406.25,67.66c6.53,5.23,39.48-10.13,31.81-39.83l7.67-2.67'
						/>
						<path
							className={`char4-fl styleStroke`}
							d='M308.5,131.8c3.4,21,0.5,18.2-1.4,22.3c-3.3,6.8,1.8,7.7,2,11c0.3,5.4,0.2,23.1,0,26.7'
						/>
					</g>
					<path
						className={`char4-fr styleStroke`}
						d='M340.62,134.81c5.38,14.95,4.89,14.77,8,18,5.21,5.47.92,8.86,1.75,12,1.38,5.24,3.19,10.6,4.46,14,7.73-1.5,11.72-1.8,19.45-3.3'
					/>
				</g>
				<line
					className={`styleStroke`}
					x1='1.5'
					y1='703.88'
					x2='636.34'
					y2='703.88'
				/>
			</svg>
		</CharactersBalanceStyle>
	);
});

export default CharactersBalance;
