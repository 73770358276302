import { memo as Memo, useMemo, useRef, useEffect } from 'react';
import { gsap } from 'gsap';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Helpers
import breakString from '@helpers/breakString';

//* Components
import Button from '@components/global/Button';
import MinTitle from '@components/global/MinTitle';
import CharactersBalance from '@/components/global/SvgComponents/CharactersBalance';

//* Styles
import WhatWeDoStyle from './style';

const WhatWeDo = Memo(({ data, active, translate }) => {
    //! Refs
    const topRef = useRef();

    //! Animation
    const tl = useMemo(() => gsap.timeline({ delay: 0.8 }), []);

    useEffect(() => {
        const arr = [topRef.current.childNodes[0].childNodes, topRef.current.childNodes[1]];

        if (active) {
            tl.staggerTo(
                [arr],
                0,
                {
                    opacity: 1,
                },
                0.2
            );
        } else {
            tl.staggerTo(
                [arr],
                0,
                {
                    opacity: 0,
                },
                0
            );
        }
    }, [active]);

    const chars = useMemo(() => <CharactersBalance />, []);

    return (
        <WhatWeDoStyle>
            <MinTitle
                tag='p'
                active={active}
                title={breakString(data.title)}
            />

            <div
                ref={topRef}
                className={'section_cont'}>
                <div className={'top_section_info_wrap'}>
                    <h2 className={`h6 font-poppins font-light subtitle`}>{data.subtitle}</h2>
                    <p className={`p-m font-poppins font-light description`}>{data.description}</p>
                    <Button
                        text={translate('ourServices')}
                        className={`our-services-btn-wrap`}
                        otherLink={{
                            pathname: '/services/',
                        }}
                    />
                </div>
                <div className={'home_animation_element_img'}>
                    <div className={'home_animation_element_img_inner'}>{chars}</div>
                </div>
            </div>
        </WhatWeDoStyle>
    );
});

export default withLanguageContext(WhatWeDo, ['translate']);
