import styled from 'styled-components';

const WhatWeDoStyle = styled.div`
	.section_cont {
		.top_section_info_wrap {
			.description {
				margin-bottom: var(--descToBtn);
			}

			.subtitle {
				max-width: 470px;
				margin-bottom: var(--textToDescription);
			}

			.description,
			.our-services-btn-wrap {
				max-width: 355px;
			}
		}

		.home_animation_element_img {
			text-align: right;
			display: flex;
			justify-content: flex-end;
			overflow: hidden;

			.home_animation_element_img_inner {
				width: 100%;
				max-width: calc(var(--sp1x) * 58);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		.home_animation_element_img {
			margin-top: var(--sp2x);
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		.section_cont {
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 46%;
				max-width: 46%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 84%;
				}
			}

			.home_animation_element_img {
				margin-top: calc(var(--sp4x) * -1);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.section_cont {
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 45%;
				max-width: 45%;

				.description {
					max-width: 80%;
				}
			}

			.home_animation_element_img {
				flex: 0 0 44%;
				max-width: 44%;
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		.section_cont {
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 47%;
				max-width: 47%;

				.description {
					max-width: 76%;
				}
			}
			.home_animation_element_img {
				margin-top: -20px;
				flex: 0 0 47%;
				max-width: 47%;
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		.section_cont {
			.top_section_info_wrap {
				.description {
					max-width: 82%;
				}
			}

			.home_animation_element_img {
				margin-top: calc(var(--sp8x) * -1);
				flex: 0 0 49%;
				max-width: 49%;

				.home_animation_element_img_inner {
					max-width: 70%;
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		.section_cont {
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 45.5%;
				max-width: 45.5%;

				.description {
					max-width: 73%;
				}
			}

			.home_animation_element_img {
				margin-top: calc(var(--sp9x) * -1);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		.section_cont {
			.top_section_info_wrap {
				.subtitle {
					max-width: 70%;
				}

				.description {
					max-width: 52%;
				}
			}

			.home_animation_element_img {
				img {
					max-width: 66%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;

			.top_section_info_wrap {
				flex: 0 0 50%;
				max-width: 50%;

				.subtitle {
					max-width: 105%;
					margin-right: -5%;
				}

				.description {
					max-width: 80%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				flex: 0 0 50%;
				max-width: 50%;
				margin-top: calc(-1 * var(--sp5x));

				img {
					max-width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 46%;
				max-width: 46%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 84%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				flex: 0 0 50%;
				max-width: 50%;
				margin-top: calc(var(--sp4x) * -1) img {
					max-width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 45%;
				max-width: 45%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 80%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				flex: 0 0 44%;
				max-width: 44%;
				margin-top: calc(var(--sp4x) * -1);

				img {
					max-width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 47%;
				max-width: 47%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 76%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				margin-top: calc(-1 * var(--sp2-5x));
				flex: 0 0 47%;
				max-width: 47%;

				img {
					max-width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 47%;
				max-width: 47%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 82%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				margin-top: calc(var(--sp8x) * -1);
				flex: 0 0 49%;
				max-width: 49%;

				img {
					max-width: 100%;
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		.section_cont {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 45.5%;
				max-width: 45.5%;

				.subtitle {
					max-width: 100%;
					margin-right: 0;
				}

				.description {
					max-width: 73%;
				}
			}

			.home_animation_element_img {
				display: flex;
				align-items: flex-end;
				margin-top: calc(var(--sp9x) * -1);
				flex: 0 0 49%;
				max-width: 49%;

				img {
					max-width: 100%;
				}
			}
		}
	}
`;

export default WhatWeDoStyle;
