'use client';

import { memo as Memo, useMemo, useRef, useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import Page from '@components/global/Page';
import HomeTop from '@components/pages/Home/HomeTop';
import WhatWeDo from '@components/pages/Home/WhatWeDo';
import AwardsCont from '@components/global/AwardsCont';
import AnimationWrap from '@components/global/AnimationWrap';
import HTMLContainer from '@components/global/HTMLContainer';

const Testimonials = dynamic(() => import('@components/global/Testimonials'))
const PortfolioShortList = dynamic(() => import('@components/global/PortfolioShortList'))

//* Styles
import HomeStyle from '@/styles/pages/HomeStyle';


const Home = Memo(({ pageData, winHeight }) => {
    //! States
    const [secObj1, setSecObj1] = useState();
    const [secObj, setSecObj] = useState({
        sec1: false,
    });

    //! Refs
    const sec1 = useRef();

    //! Handle Active Section Animation
    const Anim = useCallback(
        (x) => {
            if (JSON.stringify(x) !== JSON.stringify(secObj1)) {
                setSecObj1(x);
            }
        },
        [secObj1]
    );

    const sectionAnimation = useCallback(() => {
        let arr = [];

        if (sec1.current) {
            arr = [sec1?.current?.getBoundingClientRect()];

            let newObj = secObj;

            for (let i = 0; i < arr.length; i++) {
                newObj = { ...newObj, [`sec${i + 1}`]: arr[i].top < winHeight };
            }

            if (JSON.stringify(newObj) !== JSON.stringify(secObj)) {
                setSecObj(newObj);
            }
        }
    }, [sec1, secObj]);

    useEffect(() => {
        window.addEventListener('scroll', sectionAnimation);

        return () => {
            window.removeEventListener('scroll', sectionAnimation);
        };
    }, [sectionAnimation]);

    const awardsProps = useMemo(() => {
        let obj = pageData && {
            parentClassName: 'crStick',
            link: '/awards/',
            data: {
                title: pageData.awards.title,
                subtitle: pageData.awards.subtitle,
                description: pageData.awards.description,
                settings: pageData.awards.settings,
            },
            count: 4,
            anime: secObj.sec1,
        };

        return obj;
    }, [pageData, secObj]);

    return (
        <Page>
            <HomeStyle className='homePage'>
                <HTMLContainer full>
                    <div className='section-item min-height crStar'>
                        <HomeTop
                            data={{
                                title: pageData.hero.title,
                                subtitle: pageData.hero.subtitle,
                                description: pageData.hero.description,
                            }}
                        />
                    </div>

                    <AnimationWrap state={Anim}>
                        <div className='section-item not-margin crCircle'>
                            <WhatWeDo
                                active={secObj1?.sec1}
                                data={{
                                    title: pageData.what_we_do.title,
                                    subtitle: pageData.what_we_do.subtitle,
                                    description: pageData.what_we_do.description,
                                }}
                            />
                        </div>

                        <div className='section-item'>
                            <PortfolioShortList
                                active={secObj1?.sec2}
                                title={pageData.works.title}
                            />
                        </div>
                    </AnimationWrap>
                </HTMLContainer>

                <div ref={sec1}>
                    <AwardsCont {...awardsProps} />
                </div>

                {pageData.testimonials?.length > 0 ? (
                    <Testimonials
                        title={'testimo</br>nials'}
                        items={pageData.testimonials}
                    />
                ) : null}
            </HomeStyle>
        </Page>
    );
});

export default withUIContext(Home, ['winHeight']);
