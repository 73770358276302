import styled from 'styled-components';

const HomeTopStyle = styled.div`
	.size-new {
		font-size: var(--h7);
	}

	.top_section_wrap {
		margin-top: var(--titleToText);

		.description {
			opacity: 0;
		}

		.subtitle {
			opacity: 0;
			max-width: 80%;
		}

		.social_icon_container {
			opacity: 0;
			margin-top: var(--sp7x);
			margin-bottom: var(--sp8x);
			bottom: var(--sp3x);
		}

		.home_animation_element_wrap {
			opacity: 0;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.home_animation_element_img {
				max-width: 50%;
				position: relative;
				padding-top: 50%;
				width: 100%;

				svg {
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}

		.home_animation_element_text_wrap {
			position: relative;
			max-width: var(--sp28x);
			margin-top: var(--sp12x);
			margin-bottom: calc(-1 * var(--sp10x));

			span {
				display: block;
			}

			.home_animation_element_top {
				position: absolute;
				top: 0;
				left: 50%;
				width: 100%;
				text-align: center;
				transform: translate3d(-50%, 0%, 0);
			}

			.home_animation_element_top_ls {
				letter-spacing: -1px;
			}

			.home_animation_element_bottom {
				overflow: hidden;
				padding-right: var(--sp2x);
				padding-left: var(--sp2x);
				margin-top: -21%;

				.circle_svg {
					width: 100%;
					overflow: visible;

					.circle {
						fill: none;
					}

					.circle_text {
						font-size: var(--sp3-5x);
						fill: var(--color2);
					}
				}
			}

			.home_animation_element_bottom_ls {
				letter-spacing: -2px;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		.home_animation_element_wrap {
			.home_animation_element_text_wrap {
				margin-top: var(--sp18x) !important;
				margin-bottom: 0 !important;
			}
		}

		.social_icon_container {
			bottom: 0 !important;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		.title {
			margin-top: var(--sp2x);
		}

		.top_section_wrap {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.top_section_info_wrap {
				flex: 0 0 60%;
				max-width: 60%;

				.description {
					margin-top: var(--sp5x);
				}

				.subtitle {
					max-width: 100%;
				}

				.social_icon_container {
					margin-top: 0;
					margin-bottom: 0;
					position: absolute;
				}
			}

			.home_animation_element_wrap {
				flex: 1 1 auto;
				max-width: var(--sp28x);
				flex-direction: column;

				.home_animation_element_img {
					max-width: 100%;
					padding-top: 100%;
					margin-top: -40%;

					svg {
						width: 100%;
						left: 0;
						margin: 0 auto;
					}
				}

				.home_animation_element_text_wrap {
					max-width: 100%;
					margin-top: var(--sp4x);
					margin-bottom: var(--sp4x);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.title {
			margin-top: calc(var(--sp1x) * -1);
		}

		.top_section_wrap {
			.top_section_info_wrap {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				/* flex: 0 0 72%;
				max-width: 72%; */

				flex: 0 0 74%;
				max-width: 74%;

				.description {
					margin-top: 0;
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 53%;
					max-width: 53%;
				}

				.subtitle {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 42%;
					max-width: 42%;
				}
			}

			.home_animation_element_wrap {
				.home_animation_element_img {
					margin-top: -100%;
				}

				.home_animation_element_text_wrap {
					margin-top: var(--sp4x);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		.top_section_wrap {
			.top_section_info_wrap {
				flex: 0 0 78.5%;
				max-width: 78.5%;

				.description {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 57%;
					max-width: 57%;
				}

				.subtitle {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 38.5%;
					max-width: 38.5%;
				}
			}

			.home_animation_element_wrap {
				.home_animation_element_text_wrap {
					margin-top: var(--sp5x);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.top_section_wrap {
			.top_section_info_wrap {
				.description {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 60%;
					max-width: 60%;
				}

				.subtitle {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 37%;
					max-width: 37%;
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		.title {
			margin-top: calc(var(--sp2x) * -1);
		}

		.top_section_wrap {
			.top_section_info_wrap {
				.subtitle {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 34%;
					max-width: 34%;
				}

				.description {
					/* flex: 0 0 48%;
					max-width: 48%; */
					flex: 0 0 58%;
					max-width: 58%;
				}
			}

			.home_animation_element_wrap {
				margin-top: calc(-1 * var(--sp10x));

				.home_animation_element_text_wrap {
					margin-top: var(--sp13x);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		.title {
			margin-top: calc(var(--sp3x) * -1);
		}

		.top_section_wrap {
			.top_section_info_wrap {
				flex: 0 0 80%;
				max-width: 80%;

				.subtitle {
					/* flex: 0 0 43%;
					max-width: 43%; */
					flex: 0 0 30%;
					max-width: 30%;
				}

				.description {
					/* flex: 0 0 53%;
					max-width: 53%; */
					flex: 0 0 61%;
					max-width: 61%;
				}
			}

			.home_animation_element_wrap {
				max-width: var(--sp35x);

				.home_animation_element_img {
					max-width: var(--sp37x);
					padding-top: 86%;
					margin-top: -86%;
				}

				.home_animation_element_text_wrap {
					.home_animation_element_bottom {
						max-width: var(--sp37x);
						margin-top: -28%;
						margin-right: auto;
						margin-left: auto;
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) {
		.title {
			margin-top: calc((var(--sp1) + 2) * -1);
		}

		.top_section_wrap {
			.top_section_info_wrap {
				/* flex: 0 0 80%;
				max-width: 80%; */
				flex: 0 0 76%;
				max-width: 76%;

				.subtitle {
					/* flex: 0 0 43%;
					max-width: 43%; */
					flex: 0 0 33%;
					max-width: 33%;
				}

				.description {
					/* flex: 0 0 53%;
					max-width: 53%; */
					flex: 0 0 61%;
					max-width: 61%;
				}

				.social_icon_container {
					bottom: var(--sp9x);
				}
			}

			.home_animation_element_wrap {
				margin-top: calc(-1 * var(--sp14x));
				max-width: var(--sp37x);

				.home_animation_element_img {
					padding-top: 62%;
					margin-top: -62%;
				}

				.home_animation_element_text_wrap {
					margin-top: var(--sp17x);

					.home_animation_element_bottom {
						margin-top: -17%;
					}
				}
			}
		}
	}
`;

export default HomeTopStyle;
