import { memo as Memo, useRef, useEffect } from 'react';
import { gsap } from 'gsap';

//* Styles
import MinTitleStyle from './style';

const MinTitle = Memo((props) => {
	//! Refs
	let ref = useRef(null);

	//! Animation
	const tl = gsap.timeline();

	useEffect(() => {
		let arr = [ref?.current?.children];

		if (props.active) {
			if (arr[0][1]) {
				tl.staggerTo(
					arr[0],
					0,
					{
						opacity: 1,
					},
					0.2
				).to(arr[0][1], 0, {
					delay: 0.3,
					css: { fontStyle: 'italic' },
				});
			} else {
				tl.staggerTo(
					arr[0],
					0,
					{
						opacity: 1,
					},
					0.2
				).to(arr[0][0], 0, {
					delay: 0.3,
					css: { fontStyle: 'normal' },
				});
			}
		} else {
			tl.staggerTo(
				arr[0],
				0,
				{
					opacity: 0,
				},
				0
			).to(arr[0][1] || arr[0][0], 0, {
				css: { fontStyle: 'normal' },
			});
		}
	}, [props.active]);

	return (
		<MinTitleStyle
			ref={ref}
			className={`h2 font-poppins font-semi-bold title ${props.className || ''}`}>
			{props.title}
		</MinTitleStyle>
	);
});

export default MinTitle;
